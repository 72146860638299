
export enum AddonsStatus {
  TOOLKIT = 'paid',
  ECERT = 'paid',
  HCD = 'paid'
};
export enum AddonsStatusForLifetimeUnlimited {
  TOOLKIT='free',
  ECERT='free',
  HCD='paid'
}
export enum AddonsStatusForLifetimeFaculty {
  TOOLKIT='free',
  ECERT='free',
  HCD='paid'
}

export enum AddonsStatusForLifetimeBasic {
  TOOLKIT='free',
  ECERT='paid',
  HCD='paid'
}

export const TOOLKIT_FREE_COUNTRIES = ['in', 'za'];

export enum OfferType {
  defaultOfferType = 'SKIP2',
  skip = 'SKIP',
  skip2 = 'SKIP2',
  skip3 = 'SKIP3',
  prepay70 = 'PREPAY-70',
  prepay75 = 'PREPAY-75',
  prepay80 = 'PREPAY-80',
  prepay90 = 'PREPAY-90',
  prepay = 'PREPAY',
  cc = 'CC',
  cc7 = 'CC7',
  cc14 = 'CC14',
  gift1m = 'GIFT-1M',
  gift3m = 'GIFT-3M',
  gift6m = 'GIFT-6M',
  gift12m = 'GIFT-12M',
  ltbasic = 'LT-BASIC'
};

export const getDefaultOfferType = (
  countryCode = '', courseSlug = '', partnerIdentifier = '',
) => {
  return OfferType.defaultOfferType;
};

export enum Schedule {
  VERSION = 'v1',
};

export enum ReferralCode {
  ENABLE = 0,
};

export const autoPopupInContentPage = true;

export const skipPhoneInputFormEligibleCountries = ['in', 'ng', 'ph'];

export const rebrandDesignEligibleCountries = ['in', 'ca', 'ph', 'za'];

export const shouldShowCCPage = (offerType: string) => {
if (offerType.indexOf('GIFT') === 0) {
  return false;
}
return true;
// const eligibleOffers = ['CC', 'SKIP'];
// return eligibleOffers.includes(offerType);
};

const enableTrialInformationPage = false;

export const shouldShowTrialInfoPage = (
    offerType: string, countryCode: string, courseSlug: string,
) => {
  if (enableTrialInformationPage) {
    if (['CC', 'CC7', 'CC14', 'SKIP'].includes(offerType)) {
      return true;
    }
    return false;
  }
  return false;
};

export const getOfferTrialDays = (offerType: string) => {
// TODO: Verify
let offerTrialDays = 28;
if (offerType === 'GIFT-1M') {
  offerTrialDays = 30;
} else if (offerType === 'GIFT-3M') {
  offerTrialDays = 90;
} else if (offerType === 'GIFT-6M') {
  offerTrialDays = 180;
} else if (offerType === 'GIFT-12M') {
  offerTrialDays = 365;
} else if (offerType === 'CC7') {
  offerTrialDays = 7;
} else if (offerType === 'CC14') {
  offerTrialDays = 14;
} else if (offerType === 'SKIP2') {
  offerTrialDays = 60;
} else if (offerType === 'SKIP3') {
  offerTrialDays = 90;
}
return offerTrialDays;
};

export const offerCodeMapping = {
'JN1M': 'GIFT-1M',
'JL3M': 'GIFT-3M',
'AU6M': 'GIFT-6M',
'SE12M': 'GIFT-12M',
'SKIP': 'SKIP',
'SKIP2': 'SKIP2',
'SKIP3': 'SKIP3',
'CC': 'CC',
'CC7': 'CC7',
'CC14': 'CC14',
'PREPAY-70': 'PREPAY-70',
'PREPAY-75': 'PREPAY-75',
'PREPAY-80': 'PREPAY-80',
'PREPAY-90': 'PREPAY-90',
'PREPAY': 'PREPAY',
'LT-BASIC': 'LT-BASIC',
'LT-UNLIMITED': 'LT-UNLIMITED',
'LT-ET': 'LT-ET',
'LT-EF': 'LT-EF',
'LT-NY': 'LT-NY',
'LT-NT': 'LT-NT',
'LT-NF': 'LT-NF',
'OCLT': 'GIFT-LT',
};

export const showVideoPreviewOnCoursePage = false;

export const getPaymentLinkVersionPrefix = (
  data: {partnerIdentifier?: string, country?: string},
) => {
if (data.partnerIdentifier === 'tmobile') {
  return '/';
}
return '/v2/';
};
